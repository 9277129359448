.backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.backdrop.visible {
  display: block;
}

.sidebar {
  width: 0;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: width 0.5s ease-in-out;
}

.sidebar.open {
  width: 450px;
}


/* SLIDER STYLES */

.slider {
  width: 100%;
  height: 10px;
  background-color: #000066;
  margin-top: 20px;
  border-radius: 20px;
}

.slider .thumb{
  height: 23px;
  width: 23px;
  border-radius: 50%;
  background: #CC9933;
  border: 2px solid #4f4634;
  cursor: grab;
  margin-top: -7px;
}

@media screen and (max-width: 500px) {
  .sidebar.open {
    width: 95%;
  }
}

