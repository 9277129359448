@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600&display=swap');

body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
}

input:focus{
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button, textarea {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.footer{
  background-image: url('./assets/footer.png');
}

.auth-wallaper{
    background-image: url('./assets/bgimg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.glass-effect{
  background: rgba(255, 255, 255, 0.54);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(19.9px);
  -webkit-backdrop-filter: blur(19.9px);
  border: 1px solid rgba(255, 255, 255, 0.71);
}

.side-image{
  background-image: url('./assets/img.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.placeholder-input {
  overflow: hidden;
  white-space: nowrap;
}

.typewriter {
  width: 0;
  animation: typing 2s steps(30, end) infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Additional styles for the container if needed */
.placeholder-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.blob {
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}

.blob:hover{
  animation: none
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgb(19, 165, 255);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgb(125, 205, 255);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgb(159, 218, 255);
	}
}

.ant-modal-content{
  background: transparent !important;
  box-shadow: none !important;
}

.accordion__content{
  background-color: transparent;
  overflow: hidden;
  transition: max-height 0.6s ease;
  max-height: 0;
  color: #5C5C5C;
}

:where(.css-dev-only-do-not-override-dkbvqv).ant-modal .ant-modal-close {
  top: 40px;
  right: 40px;
  color: gray
}

:where(.css-dev-only-do-not-override-dkbvqv).ant-slider .ant-slider-handle:hover::after, :where(.css-dev-only-do-not-override-dkbvqv).ant-slider .ant-slider-handle:active::after, :where(.css-dev-only-do-not-override-dkbvqv).ant-slider .ant-slider-handle:focus::after {
    box-shadow: 0 0 0 4px #000066;
    width: 12px;
    height: 12px;
    inset-inline-start: -1px;
    inset-block-start: -1px;
}

:where(.css-dev-only-do-not-override-dkbvqv).ant-slider .ant-slider-track {
    background-color: #000066;
    border-radius: 2px;
}

:where(.css-dev-only-do-not-override-dkbvqv).ant-slider .ant-slider-handle::after {
  background-color: #CC9933;
}

:where(.css-dev-only-do-not-override-dkbvqv).ant-slider .ant-slider-handle::after {
  background-color: #ff9900;
}

.carousel-container {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.carousel-slide {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-slide img {
  width: 100%;
  height: auto;
}

.active {
  transform: translateX(0);
}

/* Add styles for next and previous slide transitions */
.active + img {
  transform: translateX(100%);
}

.active ~ img {
  transform: translateX(-100%);
}

button {
  font-size: 1.5rem;
  cursor: pointer;
}

.react-multiple-carousel__arrow--right{
  right: calc(0px) !important;
  top: 40% !important; 
}

.react-multiple-carousel__arrow--left{
  left: calc(0px) !important;
  top: 40% !important; 
}


@media screen and (max-width: 500px) {
  .slider-display-image{
    height: 200px !important;
    display: none !important;
  }
}